import { MdViewColumn, MdViewList } from 'react-icons/md'

import { AccountMenu } from './AccountMenu'
import { useGetSummaryBoards } from '@/hooks/boards/useGetSummaryBoards'
import { AthenasLogo } from '../AthenasLogo'
import { CustomLink } from './CustomLink'

export function SideBar() {
  const { data: summaryBoards } = useGetSummaryBoards()

  return (
    <header className="flex h-screen w-16 flex-col items-center bg-cromai-m3-sys-light-surface py-4 shadow-lightElevation1 dark:bg-cromai-m3-sys-dark-surface">
      <AthenasLogo />

      <section className="mb-auto mt-10 flex flex-col gap-1">
        <CustomLink
          name="Demandas"
          href="/demands"
          icon={<MdViewList size={24} />}
        />
        <CustomLink name="QA" href="/qa" icon={<MdViewList size={24} />} />
        {summaryBoards?.map((board) => {
          const labels = {
            scan_weed_cane: 'Cana',
            scan_weed_soybean: 'Soja',
            grow_line_cane: 'Growline'
          }
          return (
            <CustomLink
              key={board.id}
              href={`/boards/${board.id}`}
              icon={<MdViewColumn size={24} />}
              name={labels[board.value]}
            />
          )
        })}
        <CustomLink
          name="Config"
          href="/boards/settings"
          icon={<MdViewColumn size={24} />}
        />
      </section>
      <AccountMenu />
    </header>
  )
}
