import { Tooltip } from '@/components'
import { useChangeQAStatus } from '@/hooks/qa-requests/mutation/useChangeQAStatus'
import { MdCheckCircle, MdOutlineCheckCircle } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'

export function QAStatus({
  isConcluded,
  reportId,
  reportIndex
}: {
  isConcluded: boolean
  reportId: number
  reportIndex: number
}) {
  const { mutate } = useChangeQAStatus()
  return (
    <Tooltip
      text={isConcluded ? 'Reabrir solicitação' : 'Concluir solicitação'}
      trigger={
        <button
          aria-label={
            isConcluded ? 'Reabrir solicitação' : 'Concluir solicitação'
          }
          onClick={() =>
            mutate({ isConcluded: !isConcluded, reportId, reportIndex })
          }
          className={twMerge(
            'flex w-[60px] items-center justify-center transition-all hover:opacity-70',
            'text-cromai-m3-sys-light-primary dark:text-cromai-m3-sys-dark-outline'
          )}
        >
          {isConcluded ? (
            <MdCheckCircle size={20} />
          ) : (
            <MdOutlineCheckCircle size={20} />
          )}
        </button>
      }
    />
  )
}
