import { apiAthenas } from '@/services/api/apiAthenas'
import { QAReport } from '@/types/QAReport'

export type QAReports = {
  initialValue: number
  finalValue: number
  totalQA: number
  requests: QAReport[]
}

type GetAllDemands = {
  signal?: AbortSignal
  action?: string
  initialValue?: string
}

export async function getAllQAReports({ signal, ...props }: GetAllDemands) {
  const params = new URLSearchParams()

  props.action && params.set('action', props.action)
  props.initialValue &&
    params.set('initialValue', props.initialValue?.toString())

  const { data } = await apiAthenas.get<QAReports>('/qa', {
    signal,
    params
  })

  return data
}
