import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useMakeGetAllQAReportsKey } from '../useMakeGetAllQAReportsKey'
import { produce } from 'immer'
import { queryClient } from '@/providers'
import { handleToast } from '@/utils/handleToast'
import { changeQAResponsible } from '@/services/modules/athenas/qa-requests/change-qa-responsible'
import { QAReports } from '@/services/modules/athenas/qa-requests/get-all-qa-reports'

type ChangeResponsibleFromQAReport = {
  reportId: number
  responsibleId: number
  reportIndex: number
}

export function useChangeQAResponsible() {
  const { queryKey } = useMakeGetAllQAReportsKey()

  const changeResponsibleFromQAReportFromCache = useCallback(
    ({ reportIndex, responsibleId }: ChangeResponsibleFromQAReport) =>
      queryClient.setQueryData<QAReports>(
        queryKey,
        produce((draft) => {
          if (!draft) return

          draft.requests[reportIndex].responsibleId = responsibleId
        })
      ),
    [queryKey]
  )

  const mutation = useMutation({
    mutationFn: async ({
      reportId,
      responsibleId,
      reportIndex
    }: ChangeResponsibleFromQAReport) => {
      await changeQAResponsible({ reportId, responsibleId })

      changeResponsibleFromQAReportFromCache({
        reportId,
        responsibleId,
        reportIndex
      })
    },
    onError: () =>
      handleToast({
        type: 'error',
        message:
          'Não foi possível adicionar o responsável ao QA. Tente novamente.'
      })
  })

  return mutation
}
