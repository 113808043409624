import { useSearchParams } from 'react-router-dom'

export const GET_ALL_QA_REPORTS_KEY = 'GET_ALL_QA_REPORTS'

export function useMakeGetAllQAReportsKey() {
  const [searchParams] = useSearchParams()

  const action = searchParams.get('action') ?? undefined
  const initialValue = searchParams.get('initialValue') ?? undefined

  const params = { action, initialValue }

  return {
    queryKey: [GET_ALL_QA_REPORTS_KEY, params],
    params
  }
}
