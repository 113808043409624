import { apiAthenas } from '@/services/api/apiAthenas'

export async function changeQAResponsible({
  reportId,
  responsibleId
}: {
  reportId: number
  responsibleId: number
}) {
  await apiAthenas.put(`/qa/${reportId}/responsible`, {
    responsibleId
  })
}
