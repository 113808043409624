import { twMerge } from '@/styles/tailwindMergeConfig'

export function Skeleton({ isLoading }: { isLoading?: boolean }) {
  return (
    <main
      role="progressbar"
      className={twMerge(
        isLoading && 'animate-pulse',
        'flex w-full flex-col items-center justify-center px-8'
      )}
    >
      <div className="mt-6 flex w-full max-w-screen-xl flex-col items-start justify-between">
        <div className="w-full overflow-hidden">
          <div className="mt-2 flex w-full justify-between">
            <div className="h-12 w-40 rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
            <div className="h-12 w-52 rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant" />
          </div>
          <div>
            <div className="mt-6 flex w-full justify-between px-3">
              {Array.from({ length: 11 }).map((_, counter) => (
                <div
                  key={counter}
                  className="h-4 w-8 rounded bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant"
                />
              ))}
            </div>
          </div>
          <div className="h-[calc(100vh-130px)] w-full ">
            {Array.from({ length: 30 }).map((_, counter) => {
              return (
                <div
                  key={counter}
                  className="mt-2 w-full rounded bg-cromai-m3-sys-light-surface-variant p-6 dark:bg-cromai-m3-sys-dark-surface-variant"
                />
              )
            })}
          </div>
        </div>
      </div>
    </main>
  )
}
