import { queryClient } from '@/providers'
import { changeQASchedule } from '@/services/modules/athenas/qa-requests/change-qa-schedule'
import { handleToast } from '@/utils/handleToast'
import { useMutation } from '@tanstack/react-query'
import { useMakeGetAllQAReportsKey } from '../useMakeGetAllQAReportsKey'

export function useChangeQASchedule({ onSuccess }: { onSuccess: () => void }) {
  const { queryKey } = useMakeGetAllQAReportsKey()
  const mutation = useMutation({
    mutationFn: changeQASchedule,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      onSuccess()
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Erro ao tentar agendar. Tente novamente.'
      })
  })

  return mutation
}
