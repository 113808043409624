import { Skeleton } from './Skeletons'

import { Navbar } from './Navbar'

import { Reports } from './Reports'
import { useGetAllQAReports } from '@/hooks/qa-requests/queries/useGetAllQAReports'

export function QAReports() {
  const { data: page, isError, isLoading } = useGetAllQAReports()

  if (isError || isLoading) {
    return <Skeleton isLoading={isLoading} />
  }

  return (
    <main className="flex size-full flex-col items-center justify-center px-8">
      <div className="mt-6 flex w-full max-w-screen-xl flex-col items-start justify-between">
        <Navbar
          initialValue={page.initialValue}
          finalValue={page.finalValue}
          totalQA={page.totalQA}
        />
        <Reports reports={page.requests} />
      </div>
    </main>
  )
}
