import { handleToast } from '@/utils/handleToast'
import { useQuery } from '@tanstack/react-query'
import { useMakeGetAllQAReportsKey } from '../useMakeGetAllQAReportsKey'
import { getAllQAReports } from '@/services/modules/athenas/qa-requests/get-all-qa-reports'
import { useEffect } from 'react'

export function useGetAllQAReports() {
  const { queryKey, params } = useMakeGetAllQAReportsKey()

  const query = useQuery({
    queryKey: queryKey,
    queryFn: ({ signal }) => getAllQAReports({ signal, ...params })
  })

  useEffect(() => {
    if (query.isError) {
      handleToast({
        message:
          'Ocorreu um erro ao carregar as solicitações. Tente novamente mais tarde.',
        type: 'error',
        autoClose: false
      })
    }
  }, [query.isError, query.error])

  return query
}
