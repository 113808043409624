import { apiAthenas } from '@/services/api/apiAthenas'

export async function getSignedUrlQAReport({
  reportId,
  qaReport
}: {
  reportId: number
  qaReport: string
}) {
  const { data } = await apiAthenas.get<string>(
    `/qa/${reportId}/download/qaReport/${qaReport}`
  )

  return data
}
