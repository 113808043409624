import { Button } from '@/components'
import { useDownloadQAReport } from '@/hooks/qa-requests/mutation/useDownloadQAReport'
import { MdDownload } from 'react-icons/md'

export function DownloadReport({
  qaReport,
  reportId
}: {
  qaReport: string
  reportId: number
}) {
  const { mutate } = useDownloadQAReport()
  return (
    <div className="flex w-[60px] items-center justify-center">
      <Button
        title="Download do relatório"
        onClick={() => mutate({ reportId, qaReport })}
        variant="outlined"
        size="icon"
      >
        <MdDownload size={12} />
      </Button>
    </div>
  )
}
