import { useCallback } from 'react'
import { queryClient } from '@/providers'
import { produce } from 'immer'

import { changeQAStatus } from '@/services/modules/athenas/qa-requests/change-qa-status'
import { useMutation } from '@tanstack/react-query'
import { handleToast } from '@/utils/handleToast'

import { useMakeGetAllQAReportsKey } from '../useMakeGetAllQAReportsKey'
import { QAReports } from '@/services/modules/athenas/qa-requests/get-all-qa-reports'

type UseChangeQAStatus = {
  isConcluded: boolean
  reportId: number
  reportIndex: number
}

export function useChangeQAStatus() {
  const { queryKey } = useMakeGetAllQAReportsKey()

  const changeQAStatusFromCache = useCallback(
    ({ isConcluded, reportIndex }: UseChangeQAStatus) =>
      queryClient.setQueryData<QAReports>(
        queryKey,
        produce((draft) => {
          if (!draft) return

          draft.requests[reportIndex].isConcluded = isConcluded
        })
      ),
    [queryKey]
  )

  const mutation = useMutation({
    mutationFn: async ({
      isConcluded,
      reportId,
      reportIndex
    }: UseChangeQAStatus) => {
      changeQAStatusFromCache({ reportIndex, isConcluded, reportId })

      await changeQAStatus({ isConcluded, reportId })
    },
    onSuccess: () => queryClient.invalidateQueries(queryKey),
    onError: () => {
      queryClient.invalidateQueries(queryKey)
      handleToast({
        type: 'error',
        message: 'Não foi possível finalizar o QA. Tente novamente.'
      })
    }
  })
  return mutation
}
