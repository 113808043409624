import { getSignedUrlQAReport } from '@/services/modules/athenas/qa-requests/get-signed-url-qa-report'
import { handleToast } from '@/utils/handleToast'
import { useMutation } from '@tanstack/react-query'

export function useDownloadQAReport() {
  const mutation = useMutation({
    mutationKey: ['downloadFile'],
    mutationFn: async ({
      reportId,
      qaReport
    }: {
      reportId: number
      qaReport: string
    }) => {
      const signedUrl = await getSignedUrlQAReport({ reportId, qaReport })

      const anchorElement = document.createElement('a')
      anchorElement.setAttribute('download', 'title')

      anchorElement.href = signedUrl
      anchorElement.click()
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível realizar o download do relatório'
      })
  })
  return mutation
}
