import { apiAthenas } from '@/services/api/apiAthenas'

export async function changeQASchedule({
  scheduleDate,
  reportId
}: {
  scheduleDate: Date | null
  reportId: number
}) {
  await apiAthenas.put(`/qa/${reportId}/schedule`, {
    scheduleDate
  })
}
