import { apiAthenas } from '@/services/api/apiAthenas'

export async function changeQAStatus({
  isConcluded,
  reportId
}: {
  isConcluded: boolean
  reportId: number
}) {
  await apiAthenas.put(`/qa/${reportId}/status`, {
    isConcluded
  })
}
