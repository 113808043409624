import { MdAdd } from 'react-icons/md'
import { Avatar, Button, LoadingCircle } from '@/components'
import { useChangeQAResponsible } from '@/hooks/qa-requests/mutation/useChangeQAResponsible'

interface UserProps {
  user: {
    name: string
    email: string
    id: number
  }
  reportId: number
  reportIndex: number
}

export function User({ user, reportId, reportIndex }: UserProps) {
  const { mutate, isLoading } = useChangeQAResponsible()

  return (
    <div
      className={`group flex w-full items-center rounded px-4 py-2 hover:visible hover:bg-[#CCDBCC] hover:dark:bg-[#445545]`}
    >
      <div className="flex items-center gap-3">
        <Avatar size="small" name={user.name} />
        <div className="flex flex-col gap-1 font-mono">
          <span className="text-start text-bodyMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
            {user.name}
          </span>
          <span className="max-w-[190px] truncate text-labelMedium text-cromai-m3-sys-light-outline dark:text-cromai-m3-sys-dark-outline">
            {user.email}
          </span>
        </div>
      </div>
      <Button
        title={`Adicionar ${user.name}`}
        size="icon"
        variant="outlined"
        className="ml-auto hidden group-hover:block"
        onClick={() =>
          mutate({
            responsibleId: user.id,
            reportIndex,
            reportId
          })
        }
      >
        {isLoading ? <LoadingCircle size={12} /> : <MdAdd size={12} />}
      </Button>
    </div>
  )
}
