import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'

import {
  DialogDescription,
  DialogTitle,
  DialogClose
} from '@radix-ui/react-dialog'
import { Button, Datepicker, Dialog } from '@/components'
import { z } from 'zod'
import { useChangeQASchedule } from '@/hooks/qa-requests/mutation/useChangeScheduleAnalysis'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { MdOutlineDateRange } from 'react-icons/md'

type ScheduleAnalysisDialogProps = {
  fullDate: string
  reportId: number
  scheduleDate: string | null
}

const scheduleSchema = z.object({
  schedule: z.date().nullable()
})

type ScheduleSchema = z.infer<typeof scheduleSchema>

export function ScheduleAnalysisDialog({
  fullDate,
  scheduleDate,
  reportId
}: ScheduleAnalysisDialogProps) {
  const [isOpen, setIsOpen] = useState(false)

  const { mutate, isLoading } = useChangeQASchedule({
    onSuccess: () => setIsOpen(false)
  })

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ScheduleSchema>({
    resolver: zodResolver(scheduleSchema)
  })

  const onSubmit = handleSubmit((data, event) => {
    event?.preventDefault()

    mutate({ reportId, scheduleDate: data.schedule })
  })

  const schedule = scheduleDate ? new Date(scheduleDate) : null

  return (
    <>
      {schedule ? (
        <button
          data-testid="date-button"
          className={twMerge(
            'flex h-9 w-[200px] items-center justify-between rounded border px-4',
            'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline'
          )}
          onClick={() => setIsOpen(true)}
        >
          {schedule.toLocaleString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}
          <MdOutlineDateRange size={24} />
        </button>
      ) : (
        <Button
          onClick={() => setIsOpen(true)}
          className="w-[200px]"
          variant="text"
          size="xs"
        >
          Agendar
        </Button>
      )}
      <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="w-[500px] p-6">
          <DialogTitle className="mb-4 text-headlineSmall text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
            Atualizar data da análise da demanda Nome da demanda
          </DialogTitle>
          <DialogDescription className="mb-4 font-mono text-bodyMedium text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
            {`O prazo de entrega da demanda é ${fullDate}. Informe a data e horário
            previsto para realizar a análise.`}
          </DialogDescription>
          <form tabIndex={0} onSubmit={onSubmit}>
            <Controller
              name="schedule"
              control={control}
              defaultValue={schedule}
              render={({ field: { onChange, value } }) => (
                <Datepicker
                  isError={!!errors.schedule}
                  autoFocus={false}
                  placeholderText="00/00/0000, 00:00"
                  className="w-[452px]"
                  selected={value}
                  onChange={onChange}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm"
                  showTimeInput
                />
              )}
            />

            <div className="mt-12 flex w-full justify-end gap-2">
              <DialogClose asChild>
                <Button type="button" variant="text">
                  Cancelar
                </Button>
              </DialogClose>
              <Button
                className="w-[5.5rem]"
                loading={isLoading}
                variant="outlined"
              >
                Atualizar
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  )
}
