import { useState } from 'react'

import { Avatar, Button, LoadingCircle } from '@/components'

import { useAuth } from '@/context/auth'
import { twMerge } from '@/styles/tailwindMergeConfig'

import { MdAdd, MdCached, MdOutlineInfo } from 'react-icons/md'

import { useUserInfos } from '@/hooks/useUserInfos'
import { useUpdateDiscordId } from '@/hooks/users/queries/useUpdateDiscordId'

export function ProfileSettings() {
  const [discordId, setDiscordId] = useState<string>('')

  const { user } = useAuth()
  const { findUserInfo } = useUserInfos()

  const { isFetching } = useUpdateDiscordId({ discordId, userId: user.id })

  const currentUser = findUserInfo(user.id)

  const currentDiscordId = currentUser?.discordId

  return (
    <main className="flex size-full items-center justify-center">
      <div className="mt-20 flex w-72 flex-col gap-6">
        <h1 className="text-titleMedium dark:text-white">Seu perfil Athenas</h1>
        <div className="flex flex-col items-center gap-4">
          <Avatar name={user.name} size="big" url={currentUser?.image} />
          {!currentUser?.image ? (
            <Button disabled variant="outlined" size="xs">
              <MdAdd size={16} />
              Adicionar imagem
            </Button>
          ) : (
            <div className="flex gap-2">
              <Button size="xs">
                <MdCached size={16} />
                Trocar imagem
              </Button>

              <Button variant="text">Remover imagem</Button>
            </div>
          )}

          <section className="w-full">
            <span className="mb-1 font-mono text-labelMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
              Nome
            </span>
            <div
              className={twMerge(
                'mb-2 w-full rounded border px-4 py-3.5 font-mono text-bodyMedium',
                'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline',
                'text-cromai-m3-sys-light-outline dark:text-cromai-m3-sys-dark-outline'
              )}
            >
              {user.name}
            </div>

            <span className="text-bodySmall dark:text-white">
              Para alterar seu nome, acesse o Cromai Atlas.
            </span>

            <a
              href={'https://atlas.cromai.com/members'}
              target="_blank"
              rel="noreferrer"
            >
              <Button size="xs" variant="outlined" className="mt-2">
                Alterar nome
              </Button>
            </a>
          </section>

          <section className="w-full">
            <div className="mb-1 flex w-full items-center justify-between">
              <span className="font-mono text-labelMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
                Seu User ID do Discord
              </span>
              <a
                title="Onde encontrar o ID"
                target="_blank"
                rel="noreferrer"
                href={
                  'https://support.discord.com/hc/pt-br/articles/206346498-Onde-posso-encontrar-minhas-IDs-de-usu%C3%A1rio-servidore-mensagem'
                }
                className="text-cromai-m3-sys-light-outline transition-all
              hover:opacity-60 dark:text-cromai-m3-sys-dark-outline"
              >
                <MdOutlineInfo size={16} />
              </a>
            </div>
            <div
              className={twMerge(
                'flex items-center',
                'mb-2 w-full rounded border font-mono text-bodyMedium',
                'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline'
              )}
            >
              <input
                aria-label="Input discord ID"
                placeholder={'ex: 123456789101112'}
                defaultValue={currentDiscordId ? currentDiscordId : ''}
                onChange={(e) => setDiscordId(e.target.value)}
                className="w-full bg-transparent px-4 py-3.5 font-mono text-labelMedium text-cromai-m3-sys-light-on-surface outline-none dark:text-cromai-m3-sys-dark-on-surface"
              />
              {isFetching && <LoadingCircle size={16} className="mr-4" />}
            </div>
          </section>
        </div>
      </div>
    </main>
  )
}
